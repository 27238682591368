//package: https://github.com/xnimorz/vanilla-masked-input guide: https://www.npmjs.com/package/react-maskinput
import React, { useCallback } from 'react';
import { IonInput, isPlatform } from '@ionic/react';
import Inputmask from "inputmask";


import NumberFormatter from '../Functions/Number';

interface IonInputMaskProps {
    value : React.Key, 
    maskType: "currency" | "creditcardnumber" | "creditardexpiration",
    onValueChanged: (value: string) => void    
    name?: string, 
    maxlength?: number, 
    onIonFocus?: (event: CustomEvent) => void
}

const IonInputMask : React.FC<IonInputMaskProps> = (props) => {
    const inputCallback = useCallback(async (input) => {
        if (!input) {
          return;
        }

        const nativeInput = await input.getInputElement();
        if(props.name) nativeInput.name = props.name;
        if(props.maxlength) nativeInput.maxLength = props.maxlength;
        if(props.onIonFocus) {
            nativeInput.addEventListener('focus', (event: any) => {                
                var customEvent: CustomEvent = new CustomEvent("onIonFocus", { detail: event });
                props.onIonFocus!(customEvent);
            });
        }

        switch(props.maskType){
            case "currency":
                Inputmask({ alias:"currency", showMaskOnHover: false }).mask(nativeInput);

                nativeInput.addEventListener('input', (event: any) => {
                    const inputValue = event.target.value === "" ? event.target.value : NumberFormatter.StringToNumber(event.target.value);
                    props.onValueChanged(inputValue);
                });

                nativeInput.addEventListener('keyup', (event: any) => {
                    if(event.key === "." || event.key === "Backspace" || event.key === "Delete"){
                        const inputValue = event.target.value === "" ? event.target.value : NumberFormatter.StringToNumber(event.target.value);
                        props.onValueChanged(inputValue);
                    }
                });

                nativeInput.inputMode = "decimal";
                break;
            case "creditcardnumber":
                Inputmask({ mask:"9999 9999 9999 9999", showMaskOnFocus: false, showMaskOnHover: false, placeholder:'' }).mask(nativeInput);
                nativeInput.addEventListener('input', (event: any) => {
                    props.onValueChanged(event.target.value);
                });
                break;
            case "creditardexpiration":
                nativeInput.addEventListener('change', (event: any) => {
                    createMask(nativeInput, 'change');
                    props.onValueChanged(event.target.value);
                });
    
                nativeInput.addEventListener('input', (event: any) => {
                    createMask(nativeInput);
                    props.onValueChanged(event.target.value);
                });
                break;
        }

        nativeInput.addEventListener('paste', (event: any) => {
            if(props.maskType === "creditardexpiration") createMask(nativeInput); 
            const value = props.maskType === "currency" ? NumberFormatter.StringToNumber(event.target.value) : event.target.value;
            props.onValueChanged(value);
        });

        nativeInput.addEventListener('cut', () => {
            props.onValueChanged("");
        });

        const createMask = (nativeInput:any, type?:any) => { 
            Inputmask.remove(nativeInput);
            setTimeout( () => {
                if(type==="change"){
                    Inputmask({ mask:"99/99", showMaskOnHover: false, showMaskOnFocus: false, placeholder:'', onBeforePaste: function(value, opts){ return beforeMask(value); }}).mask(nativeInput)
                }else{
                    Inputmask({ mask:"99/99", showMaskOnHover: false, showMaskOnFocus: false, placeholder:''}).mask(nativeInput);
                }
            },50);
        };
        
        const beforeMask = (value:any) =>{
            let processedValue = value;
            if(processedValue.indexOf("/")>-1){
                let obj = processedValue.split("/");
                let month = obj[0], year = obj[1];
                if(month.length === 1){
                    month = "0" + month;
                }

                if(year.length > 2){
                    year = year.substring(0,2);
                }

                processedValue = month+'/'+year;
            }

            return processedValue;
        };

      }, []);

        

    return (
        <IonInput {...props} ref={inputCallback} />
    );
}

export default IonInputMask;