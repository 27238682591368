import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: localStorage.getItem("payments_token"),
    practiceName: localStorage.getItem("payments_practice_name"),
    practiceLogo: localStorage.getItem("payments_practice_logo"),
    practicePhoneNumber: localStorage.getItem("payments_practice_phone_number"),
    invoice: localStorage.getItem("payments_invoice") && JSON.parse(localStorage.getItem("payments_invoice")!),
    allowSendEmail: localStorage.getItem("payments_allow_send_email") === "true",
    paymentAmount: localStorage.getItem("payments_payment_amount"),
}

const setInvoiceInfo = (state: any, action: any) => {
    localStorage.setItem("payments_token", action.payload.token);
    localStorage.setItem("payments_practice_name", action.payload.practiceName);
    localStorage.setItem("payments_practice_logo", action.payload.practiceLogo);
    localStorage.setItem("payments_practice_phone_number", action.payload.practicePhoneNumber);
    localStorage.setItem("payments_invoice", JSON.stringify(action.payload.invoice));

    return {
        ...state,
        token: action.payload.token,
        practiceName: action.payload.practiceName,
        practiceLogo: action.payload.practiceLogo,
        practicePhoneNumber: action.payload.practicePhoneNumber,
        invoice: action.payload.invoice,
        allowSendEmail: null,
        paymentAmount: null
    }
}

const deleteInvoiceInfo = (state: any, action: any) => {
    localStorage.removeItem("payments_token");
    localStorage.removeItem("payments_practice_name");
    localStorage.removeItem("payments_practice_logo");
    localStorage.removeItem("payments_practice_phone_number");
    localStorage.removeItem("payments_invoice");
    localStorage.removeItem("payments_allow_send_email");
    localStorage.removeItem("payments_payment_amount");

    return {
        ...state,
        token: null,
        practiceName: null,
        practiceLogo: null,
        practicePhoneNumber: null,
        invoice: null,
        allowSendEmail: null,
        paymentAmount: null
    }
}

const setPaymentProcessed = (state: any, action: any) => {
    localStorage.setItem("payments_allow_send_email", action.payload.allowSendEmail);
    localStorage.setItem("payments_payment_amount", action.payload.paymentAmount);

    return {
        ...state, 
        allowSendEmail: action.payload.allowSendEmail,
        paymentAmount: action.payload.paymentAmount,
    }
}

const paymentsReducer = (state = initialState, action : any) => {
    switch(action.type){
        case actionTypes.SET_INVOICE_INFO: return setInvoiceInfo(state, action);
        case actionTypes.DELETE_INVOICE_INFO: return deleteInvoiceInfo(state, action);     
        case actionTypes.SET_PAYMENT_PROCESSED: return setPaymentProcessed(state, action);                       
        default: return state;
    }
}

export default paymentsReducer;