import React, { useState } from 'react';
import { IonImg } from '@ionic/react';

interface ImageProps {
    src: string | undefined,
    textAlt: string,
    classSmallBig?: 'smallScreenLogo' | 'bigScreenLogo' | undefined,
    customStyle?: {[key: string]: any;}
}

const PracticeLogo: React.FC<ImageProps> = (props) => {   
    const [error, setError] = useState<boolean>(false);

    const onImageError = () => {
        setError(true);
    }

    return(
        <React.Fragment>
            {
                (!error) ? 
                    <IonImg src={props.src} className={`practiceLogo ${ props.classSmallBig }`} style={props.customStyle} alt={props.textAlt} onIonError = { onImageError }/> 
                    : 
                    <div style={{textAlign:'center'}}>
                        <h2 className="title">{ props.textAlt }</h2>
                    </div>
            }
        </React.Fragment>
    )
}

export default PracticeLogo;