import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonLabel,
  IonGrid,
  IonCol,
  IonRow,
  IonCardContent,
  IonList,
  IonBadge,
  useIonViewDidEnter,  
} from '@ionic/react';
import axios from '../axios';

import H3Padded from '../components/PaddedH3';
import PoweredBy from '../components/PoweredBy';
import PaymentsQuoteSummary from '../components/PaymentsQuoteSummary';
import PaymentsSkeleton from '../components/PaymentsSkeleton';
import NumberFormatter from '../Functions/Number';

import * as actionCreators from '../store/actions/index';
import withErrorHandler from '../hoc/withErrorHandler';
import PaymentsApiError from '../components/PaymentsApiError';
import PracticeLogo from '../components/PracticeLogo';

interface PaymentsProps extends RouteComponentProps<{token: string | undefined;}> {
  invoice : any,
  setInvoiceInfo : (token: string, practiceName: string, practiceLogo: string, practicePhoneNumber: string, invoice : any) => void,
  apiError: any,
  practicePhoneNumber: string,
  setPaymentProcessed : (paymentAmount: number, allowSendEmail: boolean) => void,  
}

const Payments: React.FC<PaymentsProps> = (props) => {
  const history = useHistory();
  const [invoice, setInvoice] = useState<any | undefined>(undefined);
  const [practiceLogo, setPracticeLogo] = useState<string | undefined>(undefined);
  const [practiceName, setPracticeName] = useState("");
  const token = props.match.params.token;
  const { setInvoiceInfo, setPaymentProcessed, apiError } = props;
  
  useIonViewDidEnter(() => {
    axios.get(`/Payments/Requests?id=${token}`)
      .then(response => {
        if(response){
          setPracticeLogo(response.data.logo);
          setPracticeName(response.data.practice);
          setInvoice(response.data.invoice); 
          setInvoiceInfo(token!, response.data.practice, response.data.logo, response.data.phoneNumber, response.data.invoice);

          if(response.data.invoice.amountDue <= 0){
            setPaymentProcessed(0, response.data.allowToSendEmail);
            history.push("/payments/payment-processed");
          }
        }
      });
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonTitle>{!apiError ? 'Request For Payment' : 'Oops, an error occurred'}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="content">
        {!apiError ?        
        <IonGrid style={{ paddingTop:"20px" }}>        
          <IonRow>
            <IonCol
              offsetXl="2"
              sizeXl="8"
            >  
            {invoice ?
              <IonCard>
                <IonCardContent style={{ padding:"20px 15px" }}>
                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      <IonCol
                        size="12"
                        sizeLg="6"
                      >
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <PracticeLogo src={practiceLogo} textAlt={practiceName} />
                              <p className="subtitle">has requested a payment for {invoice.type} #{invoice.name}</p>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonList>                             
                                {
                                  invoice.details.map((item: any, index: any) => {
                                    return (
                                      <IonItem key={index} lines="full" className="item-no-padding">
                                        <IonBadge slot="start" className="invoiceBadge">{NumberFormatter.ToThousandsSeparator(item.quantity)}</IonBadge>
                                        <IonLabel>
                                          <H3Padded text={item.description} cssClass="description" bold />            
                                          <small className="description-small">{item.provider}</small>
                                          {
                                            item.discounts > 0 && <H3Padded text={`Discount ${item.discountPercentage && '(' + item.discountPercentage + '%)'}`}/>
                                          }                                          
                                        </IonLabel>
                                        <IonLabel slot="end" class="ion-text-end">
                                          <H3Padded text={NumberFormatter.ToCurrency(item.totalPrice)} cssClass="amounts" bold />
                                          <small className="sub-amounts">{NumberFormatter.ToCurrency(item.priceEach)}</small>
                                          {
                                            item.discounts > 0 && <H3Padded text={NumberFormatter.ToCurrency(-item.discounts)}/>
                                          }                                          
                                        </IonLabel>
                                      </IonItem>
                                    );
                                  })
                                }
                              </IonList>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol>                        
                        <PaymentsQuoteSummary 
                          invoice={invoice}
                          paymentDetailsButton
                        />
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                </IonCardContent>                
              </IonCard>
              :
              <PaymentsSkeleton />
            }
            </IonCol>
          </IonRow>          
          <PoweredBy />          
        </IonGrid>      
        :
        <PaymentsApiError error={apiError} practiceName={practiceName} />
        }  
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state : any) => {
  return {
      practiceLogo: state.payments.practiceLogo,
      practicePhoneNumber: state.payments.practicePhoneNumber,      
  }
}

const mapDispatchToProps = (dispatch : any) => {
  return {
    setInvoiceInfo: (token: string, practiceName: string, practiceLogo: string, practicePhoneNumber: string, invoice: any) => dispatch(actionCreators.setInvoiceInfo(token, practiceName, practiceLogo, practicePhoneNumber, invoice)),
    deleteInvoiceInfo: () => dispatch(actionCreators.deleteInvoiceInfo()),
    setPaymentProcessed: (paymentAmount: number, allowSendEmail: boolean) => dispatch(actionCreators.setPaymentProcessed(paymentAmount, allowSendEmail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Payments, axios));
