import React from 'react';
import { IonCard, IonCardContent, IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";

import PoweredBy from './PoweredBy';
import PracticeLogo from './PracticeLogo';

import closeCircle from '../assets/close-circle.png';
import clockCircle from '../assets/clock-circle.png';

interface PaymentsApiErrorProps {
    error: any,
    practiceName: string,
}

const PaymentsApiError : React.FC<PaymentsApiErrorProps> = (props) => {
    return (
        <IonGrid style={{ paddingTop:"20px" }}>
            <IonRow>
                <IonCol
                offsetXl="2"
                sizeXl="8"
                >
                    <IonCard>
                        <IonCardContent style={{ padding:"20px 15px" }}>
                            <IonGrid className="ion-no-padding">
                                { props.error.practiceLogo &&
                                <IonRow>
                                    <IonCol>
                                        <PracticeLogo src={props.error.practiceLogo} textAlt={props.practiceName} customStyle={{marginBottom:"25px"}} />
                                    </IonCol>
                                </IonRow>                                                        
                                }
                                <IonRow>
                                    <IonCol>
                                        <IonImg src={props.error.expiredLink ? clockCircle : closeCircle} className="checkCircle" style={{marginBottom:"25px"}}/>
                                    </IonCol>
                                </IonRow>  
                                <IonRow>
                                    <IonCol
                                        offsetMd="4"
                                        sizeMd="4"                                            
                                        offsetSm="3"
                                        sizeSm="6"
                                    >
                                        <p className="subtitle"><strong>{props.error.errorResponseStatus}. </strong>{props.error.errorMessage}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol
                                        offsetMd="4"
                                        sizeMd="4"                                            
                                        offsetSm="3"
                                        sizeSm="6"
                                    >
                                        {props.error.practicePhoneNumber ? (
                                            <p className="subtitle">Please call our office at <IonText color="primary"><strong>{props.error.practicePhoneNumber}</strong></IonText> so that we may further assist you.</p>
                                        ) : (
                                            <p className="subtitle">Please call our office so that we may further assist you.</p>
                                        )
                                        }                                                                
                                    </IonCol>
                                </IonRow>                                                                                                                                                                                     
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            <PoweredBy />  
        </IonGrid>        
    );
}

export default PaymentsApiError;