import React from "react";
import { IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow, IonSkeletonText } from "@ionic/react";

const PaymentsSkeleton : React.FC = () => {
    return (
        <React.Fragment>
        <IonCard>
            <IonCardContent className="ion-no-padding">
                <IonGrid className="ion-no-padding">
                <IonRow>
                    <IonCol
                    sizeXs="12"
                    sizeSm="6"
                    >
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                            <IonCol>
                                <IonSkeletonText animated style={{ width: '180px', height: '80px', marginBottom:'10px', marginTop:'10px' }} className="practiceLogo" />
                                <IonSkeletonText animated style={{ width: '50%', margin:'auto' }}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                        <IonCol>                                                       
                            <IonList>              
                            {
                                [...Array(3)].map((element, index) => {
                                return(
                                    <IonItem key={index}>
                                    <IonSkeletonText animated style={{ width: '50px', height: '35px' }} slot="start" />
                                    <IonLabel>
                                        <h3>
                                        <IonSkeletonText animated style={{ width: '60%' }} />
                                        </h3>
                                        <p>
                                        <IonSkeletonText animated style={{ width: '50%' }} />
                                        </p>
                                        <p>
                                        <IonSkeletonText animated style={{ width: '50%' }} />
                                        </p>
                                    </IonLabel>
                                        <p>
                                        <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                        <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                        <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                        </p>
                                    </IonItem>
                                );
                                })                                  
                            }
                            </IonList>
                        </IonCol>
                        </IonRow>
                    </IonGrid>
                    </IonCol>
                    <IonCol>                        
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                        <IonCol>
                            <h2>
                            <IonSkeletonText animated style={{ width: '60%', margin: '15px 5px 5px 10px' }}  />
                            </h2>                              
                        </IonCol>
                        </IonRow>
                        <IonRow>
                        <IonCol>
                            <IonCard>
                            <IonCardContent className="ion-no-padding">
                                <IonList>
                                {
                                    [...Array(2)].map((element, index) => {
                                    return (
                                        <IonItem key={index}>
                                        <IonLabel>
                                            <h3>
                                            <IonSkeletonText animated style={{ width: '40%' }} />
                                            </h3>
                                            <p>
                                            <IonSkeletonText animated style={{ width: '40%' }} />
                                            </p>
                                            <p>
                                            <IonSkeletonText animated style={{ width: '40%' }} />
                                            </p>
                                        </IonLabel>
                                        <p>
                                            <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                            <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                            <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                        </p>
                                        </IonItem>
                                    );
                                    })
                                }
                                <IonItem>
                                    <IonLabel>
                                    <h3>
                                        <IonSkeletonText animated style={{ width: '40%' }} />
                                    </h3>
                                    </IonLabel>
                                    <p>
                                    <IonSkeletonText slot="end" animated style={{ width: "60px" }} />
                                    </p>
                                </IonItem>                                                                                                     
                                </IonList>
                            </IonCardContent>
                            </IonCard>
                        </IonCol>
                        </IonRow>
                        <IonRow>
                        <IonCol>
                            <IonSkeletonText animated style={{ width: '95%', marginInline:"10px", paddingTop:'20px', borderRadius:'100' }} /> <br />
                        </IonCol>
                        </IonRow>                            
                    </IonGrid>   
                    </IonCol>                      
                </IonRow>                    
                </IonGrid>
            </IonCardContent>                
        </IonCard>        
        </React.Fragment>
    );
}

export default PaymentsSkeleton;