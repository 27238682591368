import React, { useState } from "react";
import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { AxiosInstance } from "axios";

const withErrorHandler = (WrappedComponent : any, axios: AxiosInstance) => {
    const EnhancedComponent = ({...props}) => {
        const [practiceLogo, setPracticeLogo] = useState<string | null>(null);
        const [practicePhoneNumber, setPracticePhoneNumber] = useState<string | null>(null);
        const [errorMessage, setErrorMessage] = useState<string | null>(null);
        const [errorResponseStatus, setResponseStatus] = useState<string | null>(null);
        const [expiredLink, setExpiredLink] = useState<boolean | null>(null);
        
        var requestInterceptor : number = 0;
        var responseInterceptor : number = 0;

        useIonViewWillEnter(() => {
            requestInterceptor = axios.interceptors.request.use(request => {
                setErrorMessage("");
                return request;
            });
            
            responseInterceptor = axios.interceptors.response.use(response => response, error => {                
                if(props.match.path.startsWith("/payments/request-payment/")){
                    props.deleteInvoiceInfo();
                }

                if (error.response && error.response.data.message) {
                    setPracticeLogo(error.response.data.logo);
                    setPracticePhoneNumber(error.response.data.phoneNumber);
                    setErrorMessage(error.response.data.message === "Token is expired" ? "This payment link has expired." : error.response.data.message);
                    setResponseStatus(error.response.status);
                    setExpiredLink(error.response.data.message === "Token is expired");
                  } else if (error.request) {
                    setPracticeLogo(props.practiceLogo);
                    setPracticePhoneNumber(props.practicePhoneNumber);
                    setErrorMessage("The request was made but no response was received");
                    setResponseStatus(error.response.status);
                    setExpiredLink(false);                      
                  } else {
                    setPracticeLogo(props.practiceLogo);
                    setPracticePhoneNumber(props.practicePhoneNumber);
                    setErrorMessage(error.message);
                    setResponseStatus(error.response.status);
                    setExpiredLink(false);                      
                  }  

                console.log(JSON.parse(JSON.stringify(error)), error.response);
            });            
        });
       
        useIonViewWillLeave(() => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        });        

        return (
            <WrappedComponent {...props} apiError={errorMessage ? {practiceLogo, practicePhoneNumber, errorMessage, errorResponseStatus, expiredLink} : undefined} />
        );
    } 

    return EnhancedComponent;
  }  

export default withErrorHandler;