import React from 'react';
import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';

import H3Padded from './PaddedH3';
import NumberFormatter from '../Functions/Number';

interface PaymentsQuoteSummaryProps{
  invoice: any,
  paymentDetailsButton?: boolean,
}

const PaymentsQuoteSummary : React.FC<PaymentsQuoteSummaryProps> = (props) => {
    return (
        <IonGrid>
        <IonRow>
          <IonCol>
            <h2 className="title">{props.invoice.type} Summary</h2>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard className="marginInline">
              <IonCardContent>
                <IonList>
                  <IonItem lines="full" className="item-no-padding">
                    <IonLabel>
                      <H3Padded text="Subtotal" cssClass="description" />
                      <H3Padded text="Discounts" cssClass="description"/>
                      <H3Padded text="Tax:" cssClass="description" />
                    </IonLabel>
                    <IonLabel slot="end" class="ion-text-end">
                      <H3Padded text={NumberFormatter.ToCurrency(props.invoice.subTotal)} cssClass="description"/>
                      <H3Padded text={`-${NumberFormatter.ToCurrency(props.invoice.discounts)}`} bold />
                      <H3Padded text={NumberFormatter.ToCurrency(props.invoice.tax)} cssClass="description"/>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="full" className="item-no-padding">
                    <IonLabel>
                      <H3Padded text="Total:" cssClass="description" bold />
                      <H3Padded text="Pre-Payments:" cssClass="description" />                                      
                      <H3Padded text="Balance:" cssClass="description" bold />
                    </IonLabel>
                    <IonLabel slot="end" class="ion-text-end">
                      <H3Padded text={NumberFormatter.ToCurrency(props.invoice.total)} bold/>
                      <H3Padded text={NumberFormatter.ToCurrency(props.invoice.prePayments)} cssClass="description"/>                                      
                      <H3Padded text={NumberFormatter.ToCurrency(props.invoice.balance)} bold />                                        
                    </IonLabel>
                  </IonItem> 
                  <IonItem lines="none" className="item-no-padding">
                    <IonLabel>
                      <H3Padded text="Amount Due:" cssClass="description" bold />
                    </IonLabel>
                    <IonLabel slot="end" class="ion-text-end">
                      <H3Padded text={NumberFormatter.ToCurrency(props.invoice.amountDue)} cssClass="amounts" bold />
                    </IonLabel>
                  </IonItem>                                                                                                              
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        {
          props.paymentDetailsButton && (
            <IonRow>
              <IonCol>
                <IonButton mode="md" routerLink="/payments/payment-details" expand="block" className="action-button">MAKE A PAYMENT</IonButton><br />
              </IonCol>
            </IonRow>        
          )
        }
      </IonGrid>        
    );
}

export default PaymentsQuoteSummary;