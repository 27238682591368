import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux'
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonLabel, IonPage, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import axios from '../axios';
import { checkmarkCircle } from 'ionicons/icons';

import Loading from '../components/Loading';
import PoweredBy from '../components/PoweredBy';
import withErrorHandler from '../hoc/withErrorHandler';
import ErrorBox from '../components/ErrorBox';
import NumberFormatter from '../Functions/Number';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

import checkCircle from '../assets/check-circle.png';
import PracticeLogo from '../components/PracticeLogo';

interface PaymentProcessedProps {
    token: string, 
    practiceName: string,
    practiceLogo: string,
    allowSendEmail: boolean,
    invoice: any,
    paymentAmount: number,
    apiError : any,
}

const PaymentProcessed : React.FC<PaymentProcessedProps> = (props) => {
    const history = useHistory();
    const [balance, setBalance] = useState(0);
    const [showEmailSent, setShowEmailSent] = useState(false);
    const [showLoading, setShowLoading] = useState(false);    
    const { token, practiceName, practiceLogo, allowSendEmail, invoice, paymentAmount } = props;
    const { apiError } = props;    

    useEffect(() => {
        if(invoice){
            setBalance(invoice.amountDue - paymentAmount);
        } else {
            history.push("/not-authorized");
        }
    }, [invoice, history, paymentAmount]);

    const emailReceiptClick = () => {
        setShowLoading(true);

        const requestParams = JSON.stringify({
            "paymentRequestId": token
        });

        var requestConfig = {
            headers: {
                "Accept": "text/plain",
                "Content-Type": "application/json",
            }
        };

        axios.post('/Payments/Receipts', requestParams, requestConfig)
            .then(response => {
                if(response){
                    setShowEmailSent(true);
                }                
            })   
            .finally(() => setShowLoading(false));       
    }

    const printReceiptClick = () => {
        setShowLoading(true);

        axios.get(`/Payments/Receipts?PaymentRequestId=${token}`)
        .then(response => {
          if(response){
            InAppBrowser.create(response.data.receipt, '_blank');
          }
        })
        .finally(() => setShowLoading(false));  
    }
    
    const makeAnotherPaymentClick = () => {
        window.location.href=`/payments/request-payment/${token}`; //used this way in order to refresh the request payment and reload the invoice information
    }

    const onLoadingDismiss = () => {
        //do nothing
    }

    return (
        <IonPage>
            <IonToast
                isOpen={showEmailSent}
                onDidDismiss={() => setShowEmailSent(false)}
                message="Email was sent successfully"
                duration={3000}
                position="top"
                color="success"
                buttons={[
                    {
                        side: 'start',
                        icon: checkmarkCircle,
                        text: '',
                    }
                ]}        
            />  
            <IonHeader>
                <IonToolbar mode="ios">
                    <IonTitle>Payment Processed</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="content">
                <IonGrid style={{ paddingTop:"20px" }}>
                    <IonRow>
                        <IonCol
                            offsetXl="2"
                            sizeXl="8"
                        >
                            <IonCard>
                                <IonCardContent style={{ padding:"20px 15px" }}>
                                    {apiError &&
                                        apiError && <ErrorBox message={`We were unable to process your request. ${apiError.errorMessage} (${apiError.errorResponseStatus}).`} />
                                    }
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <PracticeLogo src={practiceLogo} textAlt={practiceName} customStyle={{marginBottom:"25px"}} />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonImg src={checkCircle} className="checkCircle" style={{marginBottom:"25px"}}/>
                                            </IonCol>
                                        </IonRow>                                          
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                {paymentAmount > 0 ?
                                                    <p className="subtitle">Your payment of {NumberFormatter.ToCurrency(paymentAmount)} was processed successfully!</p>
                                                    :
                                                    <p className="subtitle">Your balance is <strong>{NumberFormatter.ToCurrency(0)}</strong></p>
                                                }
                                            </IonCol>
                                        </IonRow>                                        
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <IonButton mode="md" expand="block" onClick={printReceiptClick} className="action-button" style={{marginBottom:"15px"}}>
                                                    <FontAwesomeIcon className="fas fa-print fa-button-icon" />    
                                                    <IonLabel>PRINT RECEIPT</IonLabel>
                                                </IonButton>                                                
                                            </IonCol>
                                        </IonRow>
                                        { allowSendEmail &&
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <IonButton mode="md" expand="block" className="action-button" onClick={emailReceiptClick}>
                                                    <FontAwesomeIcon className="fas fa-envelope-open-dollar fa-button-icon" />
                                                    <IonLabel>EMAIL RECEIPT</IonLabel>
                                                </IonButton> 
                                            </IonCol>
                                        </IonRow>  
                                        }
                                        {(paymentAmount > 0 && balance > 0) &&
                                        <React.Fragment>
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <p className="subtitle">You have a balance of <strong>{NumberFormatter.ToCurrency(balance)}</strong></p>
                                            </IonCol>
                                        </IonRow> 
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <IonButton mode="md" expand="block" className="action-button" onClick={makeAnotherPaymentClick}>
                                                    <IonLabel>MAKE ANOTHER PAYMENT</IonLabel>
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>     
                                        </React.Fragment>
                                        }
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <PoweredBy />
                </IonGrid>   
                <Loading show={showLoading} onDidDismiss={onLoadingDismiss} message="Please wait..." />                                         
            </IonContent>
        </IonPage>
    );
}

const mapStateToProps = (state : any) => {
    return {
        token: state.payments.token,
        practiceName: state.payments.practiceName,
        practiceLogo: state.payments.practiceLogo,
        allowSendEmail: state.payments.allowSendEmail,
        invoice: state.payments.invoice,
        paymentAmount: state.payments.paymentAmount,
    }
}

export default connect(mapStateToProps)(withErrorHandler(PaymentProcessed, axios));