import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import Cards from 'react-credit-cards';
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonRadio, IonRadioGroup, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import axios from '../axios';
import CardValidator from 'card-validator';

import Loading from '../components/Loading';
import H3Padded from '../components/PaddedH3';
import PaymentsQuoteSummary from '../components/PaymentsQuoteSummary';
import PoweredBy from '../components/PoweredBy';
import NumberFormatter from '../Functions/Number';
import ErrorBox from '../components/ErrorBox';
import IonInputMask from '../components/IonInputMask';
import withErrorHandler from '../hoc/withErrorHandler';

import * as actionCreators from '../store/actions/index';

import 'react-credit-cards/es/styles-compiled.css';
import PracticeLogo from '../components/PracticeLogo';

type Focused = "name" | "number" | "expiry" | "cvc";

interface PaymentDetailsProps {
    token: string,
    practiceName: string,
    practiceLogo: string, 
    invoice : any,
    setPaymentProcessed: (paymentAmount: number, allowSendEmail: boolean) => void,
    apiError : any,
}

const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
    const history = useHistory();  
    const [selectedPaymentType, setSelectedPaymentType] = useState<"amountdue" | "depositamount" | "otheramount">('amountdue');
    const [depositAmount, setDepositAmount] = useState<number | undefined>(undefined);
    const [amountDue, setAmountDue] = useState("0");
    const [otherAmount, setOtherAmount] = useState<number | string>('');
    const [otherAmountError, setOtherAmountError] = useState('');    
    const [otherAmountText, setOtherAmountText] = useState('');    
    const [cardNumber, setCardNumber] = useState('');
    const [cardNumberError, setCardNumberError] = useState('');
    const [cardName, setCardName] = useState('');    
    const [cardNameError, setCardNameError] = useState('');    
    const [cardExpiry, setCardExpiry] = useState('');    
    const [cardExpiryError, setCardExpiryError] = useState('');    
    const [cardCVV, setCardCVV] = useState('');    
    const [cardCVVLenght, setCardCVVLenght] = useState(3);
    const [cardCVVError, setCardCVVError] = useState('');    
    const [cardPostalCode, setCardPostalCode] = useState(''); 
    const [cardPostalCodeError, setCardPostalCodeError] = useState(''); 
    const [cardFocus, setCardFocus] = useState<Focused | undefined>();
    const [showLoading, setShowLoading] = useState(false);
    const [disableProcessPaymentButton, setDisableProcessPaymentButton] = useState(false);     
    const { token, practiceName, practiceLogo, invoice, setPaymentProcessed, apiError } = props;

    useEffect(() => {
        if(invoice){
            setAmountDue(NumberFormatter.ToCurrency(invoice.amountDue));
            if(invoice.deposit && invoice.deposit > 0){
                setSelectedPaymentType("depositamount");
                setDepositAmount(invoice.deposit);                
            }
        } else {
            history.push("/not-authorized");
        }
    }, [invoice, history]);

    const cardNameIsValid = (value : string, isSubmit? : boolean) => {
        const cardholderName = CardValidator.cardholderName(value);

        if((!cardholderName.isPotentiallyValid && !cardholderName.isValid) || (isSubmit && !cardholderName.isValid)){
            setCardNameError("Invalid card name.");
            return false;
        } else {            
            setCardNameError("");            
            return true;
        }
    }

    const cardNumberIsValid = (value : string, isSubmit? : boolean) => {
        setCardCVVLenght(3);
        const number = CardValidator.number(value);

        if((!number.isPotentiallyValid && !number.isValid) || (isSubmit && !number.isValid)){
            setCardNumberError("Invalid card number.");
            return false;
        } else {
            setCardCVVLenght(number.card?.type === "american-express" ? 4 : 3);
            setCardNumberError("");
            return true;
        }
    }

    const cardExpiryIsValid = (value : string, isSubmit? : boolean) => {
        const expirationDate = CardValidator.expirationDate(value);

        if((!expirationDate.isPotentiallyValid && !expirationDate.isValid) || (isSubmit && !expirationDate.isValid)){
            setCardExpiryError("Invalid expiration date.");
            return false;
        } else {
            setCardExpiryError("");
            return true;
        }
    }

    const cardCVVIsValid = (value : string, lenght : number, isSubmit? : boolean) => {
        const cvv = CardValidator.cvv(value, lenght);
        
        if((!cvv.isPotentiallyValid && !cvv.isValid) || (isSubmit && !cvv.isValid)){
            setCardCVVError("Invalid CVV.");
            return false;
        } else {
            setCardCVVError("");
            return true;
        }
    }

    const cardPostalCodeIsValid = (value : string, isSubmit? : boolean) => {
        const postalCode = CardValidator.postalCode(value, {minLength: 5});
        
        if((!postalCode.isPotentiallyValid && !postalCode.isValid) || (isSubmit && !postalCode.isValid)){
            setCardPostalCodeError("Invalid postal code.");
            return false;
        } else {
            setCardPostalCodeError("");
            return true;
        }        
    }

    const onCardNumberChange = (value: string) => {
        setCardNumber(value);
        cardNumberIsValid(value);
    };

    const onCardNameChange = (event: CustomEvent) => {
        setCardName(event.detail.value);
        cardNameIsValid(event.detail.value);
    }

    const onCardExpiryChange = (value: string) => {
        setCardExpiry(value);
        cardExpiryIsValid(value);
    }

    const onCardCVVChange = (event: CustomEvent) => {
        setCardCVV(event.detail.value);
        cardCVVIsValid(event.detail.value, cardCVVLenght);
    }

    const onCardPostalCodeChange = (event: CustomEvent) => {
        setCardPostalCode(event.detail.value);
        cardPostalCodeIsValid(event.detail.value);        
    }

    const onInputFocus = (event: CustomEvent) => {
        setCardFocus(event.detail.target.name);
    }

    const cardIsValid = () => {
        const invalidCardName = !cardNameIsValid(cardName, true);
        const invalidCardNumber = !cardNumberIsValid(cardNumber, true);
        const invalidCardExpiration = !cardExpiryIsValid(cardExpiry, true);
        const invalidCardCVV = !cardCVVIsValid(cardCVV, cardCVVLenght, true);
        const invalidCardPostalCode = !cardPostalCodeIsValid(cardPostalCode, true);

        if(invalidCardName || invalidCardNumber || invalidCardExpiration || invalidCardCVV || invalidCardPostalCode){
            return false;
        }

        return true;
    }

    const processPaymentClick = () => {
        if (disableProcessPaymentButton) {
            return;
        }
        setDisableProcessPaymentButton(true);  

        var amountToPay = 0;
        switch(selectedPaymentType){
            case "amountdue":
                amountToPay = NumberFormatter.StringToNumber(amountDue);
                break;
            case "depositamount":
                amountToPay = depositAmount!;
                break;
            case "otheramount":
                const invalidOtherAmount = !otherAmountIsValid(otherAmount, true);
                if(invalidOtherAmount) return;
    
                amountToPay = parseFloat(otherAmount.toString());
                break;                                
        }

        if(!cardIsValid()) {
            debounceProccessPayment();
            return;
        }

        setShowLoading(true);               
        const requestParams = JSON.stringify({
            "paymentRequestId": token,
            "amount": amountToPay,
            "creditCard": {
                "number": cardNumber.replace(/\s/g, ''),
                "nameOnCard": cardName,
                "expiration": cardExpiry.replace("/",""),
                "cvv": cardCVV,
                "zip": cardPostalCode
            }
        });

        const requestConfig = {
            headers: {
                "Accept": "text/plain",
                "Content-Type": "application/json",
            }
        };

        axios.post('/Payments', requestParams, requestConfig)
            .then(response => {
                if(response){
                    setPaymentProcessed(amountToPay, response.data.allowSendEmail);
                    history.push("/payments/payment-processed");
                }                
            })
            .finally(() => {
                setShowLoading(false)
                debounceProccessPayment();
            });       
    }

    const debounceProccessPayment = () => {
        setTimeout(() => {
            setDisableProcessPaymentButton(false);
        }, 2000);
    }

    const onLoadingDismiss = () => {
        //do nothing
    }

    const otherAmountIsValid = (value : number | string, isSubmit? : boolean) => {
        if(value > NumberFormatter.StringToNumber(amountDue)){
            setOtherAmountError("Amount exceeds amount due.");
            return false;
        } else if (isSubmit && (value === "" || value === 0)) {
            setOtherAmountError("Amount can't be empty or zero.");
            return false;            
        } else {
            setOtherAmountError("");
            return true;
        }        
    }
        
    const onOtherAmountChange = (value : string) => {
        setOtherAmount(value);
        setOtherAmountText(value === "" ? "" : NumberFormatter.ToCurrency(parseFloat(value)));
        otherAmountIsValid(value);        
    }    

    const onPaymentTypeChange = (event : CustomEvent) => {
        if(event.detail.value === "depositamount" || event.detail.value === "amountdue" || event.detail.value === "otheramount"){
            setSelectedPaymentType(event.detail.value)
        }
    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <IonBackButton defaultHref={`/payments/request-payment/${token}`} mode="md"/>
            </IonButtons>
            <IonTitle>Payment Details</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="content">            
            { invoice && 
            <IonGrid style={{ paddingTop:"20px" }}>
                <IonRow>
                    <IonCol
                        offsetXl="2"
                        sizeXl="8"
                    >
                        <IonCard>
                            <IonCardContent style={{ padding:"20px 15px" }}> 
                                <IonGrid className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <PracticeLogo src={practiceLogo} textAlt={practiceName} classSmallBig="smallScreenLogo" />
                                        </IonCol>                               
                                    </IonRow>
                                </IonGrid>
                                <IonGrid className="ion-no-padding">
                                    <IonRow>
                                        <IonCol 
                                            size="12"
                                            sizeLg="6"                                            
                                            pushLg="6"
                                        >
                                            <PaymentsQuoteSummary invoice={invoice} />
                                        </IonCol>                                        
                                        <IonCol pullLg="6">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <PracticeLogo src={practiceLogo} textAlt={practiceName} classSmallBig="bigScreenLogo" />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <h2 className="title">1. How much would you like to pay?</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonList>
                                                            <IonRadioGroup value={selectedPaymentType} onIonChange={onPaymentTypeChange}>
                                                                { depositAmount &&
                                                                    <React.Fragment>
                                                                    <IonItem lines="none" className={ selectedPaymentType === "depositamount" ? "item-active" : "item-inactive" }>                
                                                                        <IonRadio slot="start" value="depositamount" mode="ios" className="radio-ios-circle" />
                                                                        <IonLabel style={{fontSize:"14px"}}>Deposit Amount</IonLabel>
                                                                        <IonLabel slot="end" class="ion-text-end">
                                                                            <H3Padded text={NumberFormatter.ToCurrency(depositAmount)} customStyle={{fontWeight:600}}/>
                                                                        </IonLabel>            
                                                                    </IonItem>                                                                
                                                                    <br />
                                                                    </React.Fragment>
                                                                }
                                                                <IonItem lines="none" className={ selectedPaymentType === "amountdue" ? "item-active" : "item-inactive" }>                
                                                                    <IonRadio slot="start" value="amountdue" mode="ios" className="radio-ios-circle" />
                                                                    <IonLabel style={{fontSize:"14px"}}>Amount Due</IonLabel>
                                                                    <IonLabel slot="end" class="ion-text-end">
                                                                        <H3Padded text={amountDue} customStyle={{fontWeight:600}}/>
                                                                    </IonLabel>            
                                                                </IonItem>
                                                                <br />
                                                                <div className={ selectedPaymentType === "otheramount" ? "div-active" : "div-inactive" }>
                                                                    <IonItem lines="none" className={ selectedPaymentType === "otheramount" ? "item-active-no-border" : "item-inactive" }>
                                                                        <IonRadio slot="start" value="otheramount" mode="ios" className="radio-ios-circle" />
                                                                        <IonLabel style={{fontSize:"14px"}}>Other Amount</IonLabel>
                                                                        {
                                                                            selectedPaymentType === "otheramount" && (
                                                                                <IonLabel slot="end" class="ion-text-end">
                                                                                    <H3Padded text={otherAmountText} customStyle={{fontWeight:600}}/>
                                                                                </IonLabel>                                                                            
                                                                            )                                                                    
                                                                        }
                                                                    </IonItem>    
                                                                    {
                                                                    selectedPaymentType === "otheramount" && (
                                                                            <React.Fragment>
                                                                                <IonGrid>
                                                                                    <IonRow>
                                                                                        <IonCol size="8" sizeMd="8" sizeXs="12" sizeSm="12" sizeLg="8" sizeXl="8" style={{ padding:'0px 0px 0px 50px' }} >
                                                                                            <IonItem className={ selectedPaymentType === "otheramount" ? "item-active-no-border" : "item-inactive" }>  
                                                                                                <IonLabel position="floating" >
                                                                                                    Enter Amount
                                                                                                </IonLabel>                                                                                                                                                                                                                                  
                                                                                                <IonInputMask 
                                                                                                    value={otherAmount}
                                                                                                    maskType="currency"
                                                                                                    onValueChanged={onOtherAmountChange}
                                                                                                    maxlength={18}
                                                                                                />                                                                                                                                                      
                                                                                            </IonItem>                     
                                                                                            {otherAmountError !== '' && 
                                                                                                <IonText color="danger" className="fontSizeError">{otherAmountError}</IonText>
                                                                                            }
                                                                                        </IonCol>
                                                                                    </IonRow>
                                                                                </IonGrid>
                                                                            </React.Fragment>
                                                                        )
                                                                    } 
                                                                </div>                                                                                                                                          
                                                            </IonRadioGroup>             
                                                        </IonList>                                                        
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <h2 className="title">2. Enter Payment Information</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <br/>
                                                        <Cards 
                                                            cvc={cardCVV} 
                                                            expiry={cardExpiry} 
                                                            focused={cardFocus} 
                                                            name={cardName} 
                                                            number={cardNumber}
                                                        />
                                                    </IonCol>
                                                </IonRow>  
                                                <IonRow>
                                                    <IonCol>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonItem>
                                                                        <IonLabel position="floating">Name on card</IonLabel>
                                                                        <IonInput value={cardName} name="name" onIonChange={onCardNameChange} onIonFocus={onInputFocus} maxlength={30} />                                                                        
                                                                    </IonItem>                                                        
                                                                    {cardNameError !== '' && <IonText color="danger" className="fontSizeError">{cardNameError}</IonText>
                                                                    }
                                                                </IonCol>                                        
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonItem>
                                                                        <IonLabel position="floating">Card number</IonLabel>
                                                                        <IonInputMask 
                                                                            value={cardNumber}
                                                                            maskType="creditcardnumber"
                                                                            onValueChanged={onCardNumberChange}
                                                                            onIonFocus={onInputFocus}
                                                                            name="number"
                                                                            maxlength={19}
                                                                        />
                                                                    </IonItem>                                                        
                                                                    {cardNumberError !== '' && 
                                                                        <IonText color="danger" className="fontSizeError">{cardNumberError}</IonText>
                                                                    }
                                                                </IonCol>
                                                            </IonRow>                                                            
                                                            
                                                            <IonRow>
                                                                <IonCol sizeMd="8">
                                                                    <IonItem>
                                                                        <IonLabel position="floating">Expiration date (MM/YY)</IonLabel>
                                                                        {/*<IonInput value={cardExpiry} name="expiry" onIonChange={onCardExpiryChange} onIonFocus={onInputFocus} maxlength={5} />*/}
                                                                        <IonInputMask 
                                                                            value={cardExpiry}
                                                                            maskType="creditardexpiration"
                                                                            onValueChanged={onCardExpiryChange}
                                                                            onIonFocus={onInputFocus}
                                                                            name="expiry"
                                                                            maxlength={5}
                                                                        />                                                                        
                                                                    </IonItem>                                                        
                                                                    {cardExpiryError !== '' && 
                                                                        <IonText color="danger" className="fontSizeError">{cardExpiryError}</IonText>
                                                                    }
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol sizeMd="3" >
                                                                    <IonItem>
                                                                        <IonLabel position="floating">CVV</IonLabel>
                                                                        <IonInput value={cardCVV} name="cvc" onIonChange={onCardCVVChange} onIonFocus={onInputFocus} maxlength={4}/>                                                                        
                                                                    </IonItem>                                                        
                                                                    {cardCVVError !== '' && 
                                                                        <IonText color="danger" className="fontSizeError">{cardCVVError}</IonText>
                                                                    }
                                                                </IonCol>
                                                                <IonCol sizeMd="5">
                                                                    <IonItem>
                                                                        <IonLabel position="floating">Postal Code</IonLabel>
                                                                        <IonInput value={cardPostalCode} name="postalcode" onIonChange={onCardPostalCodeChange} maxlength={5}/>
                                                                        
                                                                    </IonItem>                                                        
                                                                    {cardPostalCodeError !== '' && 
                                                                        <IonText color="danger" className="fontSizeError">{cardPostalCodeError}</IonText>
                                                                    }
                                                                </IonCol>                                                                
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <br />
                                                                    <IonButton mode="md" onClick={processPaymentClick} expand="block" className="action-button" disabled={disableProcessPaymentButton}>PROCESS PAYMENT</IonButton>                                                                                                       
                                                                    <br />
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <div className="ion-text-center">                          
                                                                        <p style={{fontSize:"10px"}}>By processing your payment, you agree to PatientNow's <IonText color="primary"><a href="https://www.mypatientnow.com/Privacy.htm" target="_blank" rel="noopener noreferrer">privacy notice</a></IonText> and <IonText color="primary"><a href="https://schedulingapp.mypatientnow.com/Terms.htm" target="_blank" rel="noopener noreferrer">conditions of use.</a></IonText></p>
                                                                    </div>                                                                                                                                        
                                                                </IonCol>
                                                            </IonRow>                                                
                                                        </IonGrid>                                              
                                                    </IonCol>                                            
                                                </IonRow>
                                            </IonGrid>
                                        </IonCol>                                        
                                    </IonRow>
                                </IonGrid>
                                {
                                    apiError && <ErrorBox message={`We were unable to process your payment. ${apiError.errorMessage} (${apiError.errorResponseStatus}).`} />
                                }                        
                            </IonCardContent>
                        </IonCard>                            
                    </IonCol>
                </IonRow>
                <PoweredBy />
            </IonGrid>
            }
            <Loading show={showLoading} onDidDismiss={onLoadingDismiss} message="Please wait..." />                              
        </IonContent>
      </IonPage>
    );
}

const mapStateToProps = (state : any) => {
    return {
        token: state.payments.token,
        practiceName: state.payments.practiceName,
        practiceLogo: state.payments.practiceLogo,
        invoice: state.payments.invoice
    }
}

const mapDispatchToProps = (dispatch : any) => {
    return {
        setPaymentProcessed: (paymentAmount: number, allowSendEmail: boolean) => dispatch(actionCreators.setPaymentProcessed(paymentAmount, allowSendEmail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler((PaymentDetails), axios));