const ToCurrency = (value : number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(value);
}

const ToThousandsSeparator = (value : number) => {
    return new Intl.NumberFormat("en-US").format(value);
}

const StringToNumber = (value : string) => {
    return Number(value.replace(/[^0-9.-]+/g,""));
}

export default {
    ToCurrency,
    ToThousandsSeparator,
    StringToNumber,
}